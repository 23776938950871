import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import 'font-awesome/css/font-awesome.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import '../../css/userHome.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ApiClient from '../../util/ApiClient';
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Table from 'react-bootstrap/Table'
import Countdown, { zeroPad } from 'react-countdown-now';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { getParameterByName } from '../../util/queryString';
import moment, { min } from 'moment';
import historyScroll from '../../assets/62867-scroll-icon.png';
import videoCamera from '../../assets/video-camera-icon.png';
import greencheck from '../../assets/Custom-Icon-Design-Flatastic-9-Accept.48.png';
import happyBot from '../../assets/happy_bot-1.png';
import localServer from '../../assets/local-server.png';
import captcha from '../../assets/captcha.png';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Formik } from "formik";
import config from '../../config';
import { HubConnectionBuilder } from '@microsoft/signalr';
const formatSec = 'HH:mm:ss A';
const now = moment().hour(6).minute(0).second(0);
function LoadingModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={5000}
                        />
                    </Col>
                </Row>

            </Modal.Body>
        </Modal >
    );
}
function CreateSeries(props) {
    return (
        <Modal  {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="seriesName">
                            <Form.Label>Series Name</Form.Label>
                            <Form.Control type="text" placeholder="Series Name" onChange={props.onHandleSeriesNameChange} value={props.newSeriesName} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="ttcEstimate">
                            <Form.Label>Estimated Minutes to Complete Series</Form.Label>
                            <Form.Control type="text" placeholder="Minutes" onChange={props.onHandleSeriesTTCEstimateChange} value={props.newSeriesTTCEstimate} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="minSubscriptionPlan">
                            <Form.Label>Minimum Subscription Plan</Form.Label>
                            <Dropdown onSelect={props.onHandleSeriesMinSubscriptionChange} style={{ marginBottom: '16px' }}>
                                <Dropdown.Toggle variant="info" id="dropdown-basic" >
                                    {props.newSeriesSelectedMinSubscription ? props.newSeriesSelectedMinSubscription : "Min Subscription Allowed"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.supportedSubscriptions.map((subscriptionName) => {
                                        return (<Dropdown.Item eventKey={subscriptionName}>{subscriptionName}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onSaveSeriesClick}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal >
    );
}
function CreateMiniBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Mini Bot Editor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.miniBotId &&
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="miniBotId">
                                <Form.Label>Editing Mini Bot</Form.Label>
                                <Form.Control type="text" placeholder="Mini Bot ID" autoComplete={"off"} disabled={true} value={props.miniBotId} />
                            </Form.Group>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="masterBotId">
                            <Form.Label>Main Bot ID
                                {props.miniBotMasterBotId ? <a target={"_blank"} href={"https://app.botit.com/create?bid=" + props.miniBotMasterBotId}> (Portal)</a> : ""}
                                {props.miniBotMasterBotId ? <a target={"_blank"} href={"/builder?bid=" + props.miniBotMasterBotId + "&linked=true"}> (Config)</a> : ""}</Form.Label>
                            <Form.Control type="text" placeholder="Mini Bot ID" autoComplete={"off"} onChange={props.onHandleMasterBotIdChange} value={props.miniBotMasterBotId} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniBotName">
                            <Form.Label>Mini Bot Name</Form.Label>
                            <Form.Control type="text" placeholder="Mini Bot Name" autoComplete={"off"} onChange={props.onHandleMiniBotNameChange} value={props.miniBotName} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="cancellationHours">
                            <Form.Label>Cancellation Hours</Form.Label>
                            <Form.Control type="number" placeholder="Cancellation Hours" autoComplete={"off"} onChange={props.onHandleCancellationHoursChange} value={props.miniBotCancellationHours} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="advanceReleaseDays">
                            <Form.Label>Advance Release Days</Form.Label>
                            <Form.Control type="number" placeholder="Advance Release Days" autoComplete={"off"} onChange={props.onHandleAdvanceReleaseDaysChange} value={props.miniBotAdvanceReleaseDays} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="timeOfRelease">
                            <Form.Label>Time of New Releases</Form.Label><br />
                            <TimePicker
                                showSecond={true}
                                onChange={props.onTimeOfReleaseChange}
                                format={formatSec}
                                use12Hours
                                inputReadOnly
                                value={props.timeOfRelease && moment(props.timeOfRelease, 'hh:mm:ss A').isValid() ? moment(props.timeOfRelease, 'hh:mm:ss A') : now}
                            />
                        </Form.Group>
                    </Col>
                    <Col hidden={!props.miniBotId}>
                        <Form.Group className="mb-3" controlId="timezoneId">
                            <Form.Label>Time Zone:</Form.Label><br />
                            <select className='form-control' onChange={props.onchangeSupportedTimezones}>
                                {props.supportedTimezones.map((timezone) => {
                                    return (<option selected={timezone.id == props.miniBotTimeZoneId} value={timezone.id}>{timezone.id}</option>)
                                })}
                            </select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Pricing Type:</Form.Label>
                        <select className='form-control' onChange={props.changeMiniPricingType}>
                            <option selected={props.pricingType == "STANDARD"} value="STANDARD">Standard</option>
                            <option selected={props.pricingType == "STANDARDPREMIUM"} value="STANDARDPREMIUM">Standard Premium</option>
                            <option selected={props.pricingType == "PROPREMIER"} value="PROPREMIER">Pro Premium</option>
                        </select>
                    </Col>
                </Row>
                {props.supportedBookingSystems &&
                    <Row hidden={!props.miniBotId}>
                        <Col>
                            <Form.Label>Booking System:</Form.Label>
                            <select className='form-control' onChange={props.onHandleBookingSystemChange}>
                                {props.supportedBookingSystems.map((bookingSystem) => {
                                    return (<option selected={bookingSystem == props.miniBotBookingSystem} value={bookingSystem}>{bookingSystem}</option>)
                                })}
                            </select>
                        </Col>
                    </Row>
                }

                <Row hidden={!props.miniBotId}>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniBotAddress">
                            <Form.Label>Street Address:</Form.Label>
                            <Form.Control type="text" placeholder="Address" autoComplete={"off"} onChange={props.onHandleMiniStreetAddressChange} value={props.miniBotStreetAddress} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniBotCity">
                            <Form.Label>City:</Form.Label>
                            <Form.Control type="text" placeholder="City" autoComplete={"off"} onChange={props.onHandleMiniCityChange} value={props.miniBotCity} />
                        </Form.Group>
                    </Col>
                </Row>
                {props.supportedStates && props.supportedCountries &&
                    <Row hidden={!props.miniBotId}>
                        <Col>
                            <Form.Group className="mb-3" controlId="miniBotZipCode">
                                <Form.Label>State:</Form.Label>
                                <select className='form-control' onChange={props.onchangeSupportedStates}>
                                    {props.supportedStates.map((state) => {
                                        return (<option selected={state.value == props.miniBotState} value={state.value}>{state.description}</option>)
                                    })}
                                </select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="miniBotZipCode">
                                <Form.Label>ZipCode:</Form.Label>
                                <Form.Control type="text" placeholder="ZipCode" autoComplete={"off"} onChange={props.onHandleMiniZipCodeChange} value={props.miniBotZipCode} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Label>Country:</Form.Label>
                            <select className='form-control' onChange={props.onchangeSupportedCountries}>
                                {props.supportedCountries.map((country) => {
                                    return (<option selected={country.value == props.miniBotSupportedCountry} value={country.value}>{country.description}</option>)
                                })}
                            </select>
                        </Col>
                    </Row>
                }

                <Row hidden={!props.miniBotId}>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniPhoneNumber">
                            <Form.Label>Phone Number:</Form.Label>
                            <Form.Control type="text" placeholder="Phone Number" autoComplete={"off"} onChange={props.onHandleMiniPhoneChange} value={props.miniBotPhoneNumber} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={!props.miniBotId}>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniVersionNumber">
                            <Form.Label>Version Number:</Form.Label>
                            <Form.Control type="text" placeholder="Version Number" autoComplete={"off"} onChange={props.onHandleMiniVersionChange} value={props.miniBotVersion} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={!props.miniBotId}>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniWebsiteUrl">
                            <Form.Label>Website URL:</Form.Label>
                            <Form.Control type="text" placeholder="Website URL" autoComplete={"off"} onChange={props.onHandleMiniWebsiteURLChange} value={props.miniBotWebsiteUrl} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniBotNotes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" onChange={props.onHandleMiniNotesChange} rows={2} value={props.miniBotNotes} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onSaveMiniBot}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal >
    );
}
function CreateDuplicateSeries(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Duplicate {props.originalName} Series</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Formik
                            initialValues={{
                                name: "",
                                originalSeriesId: props.ogSeriesId,
                            }}
                            onSubmit={(values, actions) => {
                                ApiClient.post('bot/service/duplicate/series', {
                                    originalSeriesId: props.originalSeriesId,
                                    name: values.name,
                                }).then((response) => {
                                    actions.setSubmitting(false);
                                    values.name = '';
                                    if (response.status == 200) {
                                        ApiClient.get('bot/service/config/templates/all').then((response) => {
                                            props.updateTemplateInformationFromNewDuplicate(response.data);
                                            props.onHide();
                                        }).catch((error) => {
                                            alert(error.response.error)
                                        });
                                    }

                                }).catch((error) => {
                                    actions.setSubmitting(false);
                                    values.name = '';
                                })


                            }}
                        >
                            {formik => (
                                <div>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Form.Group controlId="name">
                                            <Form.Label>New Series Name</Form.Label>
                                            <Form.Control autoComplete={"off"} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Series Name" required />
                                        </Form.Group>

                                        <Row style={{ textAlign: 'center' }}>
                                            <Col lg={12}>
                                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Create Duplicate"}</Button>
                                            </Col>
                                        </Row>

                                    </Form>

                                </div>


                            )}
                        </Formik>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function CustomerProfileModal(props) {
    return (
        <Modal  {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Customer Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>ID: {props.id}</span>
                    </Col>
                </Row>
                <Row hidden={!props.bze} style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Business Elite Tier: {props.bzeTier}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Referral Code: {props.referralUrl}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Name: {props.name}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Email Address: {props.email}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Subscription Level: {props.subscription}</span>
                        <Button variant='link' onClick={props.onResetSubscriptionClick()}>Reset</Button>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Subscription Provider: {props.provider}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Sign Up Type: {props.customerSignUpType}</span>
                        {props.customerSignUpType == "Apple" &&
                            <Button variant='link' onClick={props.onConvertAppleToEmail()}>Convert to Email</Button>
                        }
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Latest Status: {props.status}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Subscription Expiration (PST): {props.expiration ? moment(props.expiration).format("dddd, MMMM Do YYYY") : "N/A"}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Monthly Bots Ran: {props.monthlyBotRuns}</span>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Bots Allowed:</span>
                    </Col>
                    <Col>
                        <Form.Control type="int" placeholder="# of Bots Allowed" onChange={props.handleBotsAllowChange} value={props.customerBotsAllowedCount} />
                    </Col>
                    <Col>
                        <Button onClick={() => props.updateBotsAllowed(props.id)} variant="primary">Update</Button>
                    </Col>
                </Row>
                <Row style={{ margin: "15px 12px" }}>
                    <Col>
                        <span>Subscription:</span>
                    </Col>
                    <Col>
                        <Form.Control type="text" placeholder="BrainTree (ONLY) Subscription ID" onChange={props.handleUserBrainTreeSubChange} value={props.customerBrainTreeSubscriptionId} />
                    </Col>
                    <Col>
                        <Form.Select aria-label="Select Billing Type" onChange={props.handleUserSubscriptionTypeIdChange} value={props.customerMainSubscriptionTypeId}>
                            <option>Select a Subscription Type</option>
                            <option value={"5f08e5426fb3bba94c921f38"}>Starter</option>
                            <option value={"5f08e58d6fb3bba94c921f3a"}>Standard</option>
                            <option value={"5f08e5996fb3bba94c921f3b"}>Pro</option>
                            <option value={"62a11faacc63c4a27933cb8f"}>Standard Plus</option>
                            <option value={"62a11fcdcc63c4a27933cb90"}>Pro Plus</option>
                            <option value={"6027beed0619d30005eb9d22"}>Elite</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Button onClick={() => props.updateUserSubscriptionType(props.id)} variant="primary">Update</Button>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '25px' }}>
                    <Col>
                        <Button onClick={() => props.deleteEmailQueue(props.id)} variant="info">Delete Email Queue</Button>
                    </Col>
                    <Col>
                        <Button onClick={() => props.disableUserBots(props.id)} variant="warning">Disable All Bots</Button>
                    </Col>
                    <Col>
                        <Button onClick={() => props.deleteUser(props.id)} variant="danger">Delete User</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    );
}

function PublicShareBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Share Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Label>Share <b>{props.botName}</b> Url:</Form.Label>
                        <Form.Control autoComplete={"off"} disabled={true} type="email" placeholder="Share URL" value={props.shareUrl} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Button variant='secondary' onClick={props.onCopyButtonClick}>{props.copyButtonText} </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function LiveBotRunView(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>{props.botName + " - " + props.liveBotSessionId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <iframe id='inSession' style={{ width: '100%', height: '90vh' }} src={props.runningBotUrl}></iframe>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
const renderer = ({ hours, minutes, seconds }) => (
    <span>
        {minutes + ":" + zeroPad(seconds)}
    </span>
);
const access = parseInt(localStorage.getItem('l'));
@inject('rootStore')
@observer
export default class UserHome extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            showCreateSeriesModal: false,
            upComingHeaders: ["ID", "Bot Name", "Customer", "Proxy Group", "Booking Date", "Search Filter", "Starts Time"],
            upcomingInteractiveHeaders: ["ID", "Bot Name", "Customer", "Website", "Instructions", "Starts Time", "Started By", "Action"],
            failedHeaders: ["ID", "Bot Name", "Customer", "Message", "Start Time"],
            recordingHeaders: ["ID", "Bot Name"],
            noResultHeaders: ["ID", "Bot Name", "Customer"],
            biteHeaders: ["ID", "Date", "Bot Name", "Search Filter", "Start Time", "Item Click Time", "Clicked Item", "Steps Completed", "Status"],
            botHistoryHeaders: ["ID", "Start Search Time", "Search Filter", "Status", "Message", "Start-End (duration)"],
            successHeaders: ["ID", "Bot Name", "Customer"],
            nextHeaders: ["ID", "Bot Name", "User ID", "Date Created", "Assigned", "Priority Level", "Due Date"],
            userBotsHeader: ["ID", "User ID", "Upcoming Run Time (local)", "Search Filter", "Bot Name", "Booking Date", "Status"],
            seriesHeader: ["ID", "Name", "Date Created", "Question Count"],
            runningHeaders: ["Bot ID", "Name", "Session ID", "Schedule ID", "Time (pst)"],
            latestUserHeaders: ["Name", "Email", "ID", "Subscription", "Type", "Registration Date"],
            miniBotHeaders: ["Name", "Advance Release Days", "Time of New Releases (UTC)", "Hours Before Cancellation", "Mini Type"],
            upcoming: [],
            upcomingInteractive: [],
            failed: [],
            recording: [],
            noResults: [],
            success: [],
            next: [],
            userBots: [],
            userSearchBotInput: '',
            botHistorySearchInput: '',
            botHistory: [],
            series: [],
            supportedSubscriptions: [],
            newSeriesName: '',
            newSeriesTTCEstimate: '',
            newSeriesSelectedMinSubscription: '',
            botBuilderID: '',
            activeKey: 'ticker',
            timerRefresh: 300000,
            running: [],
            latestUsers: [],
            bites: [],
            botBotSearchId: '',
            userEmailOrIdInput: '',
            showUserProfileModal: false,
            customerId: '',
            bze: false,
            bzeTier: '',
            customerSearchedName: '',
            customerSignUpType: '',
            customerSearchedEmail: '',
            customerSearchedSubscription: '',
            customerSearchedProvider: '',
            customerSearchedStatus: '',
            customerSearchedExpiration: '',
            miniSearchType: '',
            miniBotTypes: [],
            miniBots: [],
            publicShareUrl: '',
            publicShareModalShow: false,
            publicShareCopyButtonText: 'Copy',
            editMiniBotModalShow: false,
            miniBotId: null,
            miniBotMasterBotId: null,
            miniBotName: null,
            miniBotCancellationHours: 0,
            miniBotAdvanceReleaseDays: 0,
            miniBotPricingType: "STANDARD",
            timeOfRelease: null,
            miniBotBookingSystem: "NA",
            miniBotCity: null,
            miniBotCountry: null,
            miniBotNotes: null,
            miniBotPhoneNumber: null,
            miniBotState: null,
            miniBotStreetAddress: null,
            miniBotTimeZoneId: null,
            miniBotVersion: 1.0,
            miniBotZipCode: null,
            miniBotWebsiteUrl: null,
            miniBotSupportedCountry: null,
            showDuplicateSeriesModal: false,
            originalSeriesId: '',
            originalName: '',
            eliteGroups: {},
            nonEliteGroups: {},
            totalMonthlyBotCount: 0,
            ipTestResults: [],
            listOfIPAddresses: '',
            confirmId: '',
            confirmClass: '',
            confirmXpath: '',
            ipTestUrl: '',
            verifyIPAddress: 'verify/ip?sessionId=',
            liveFeedStatus: '(Disconnected)',
            liveFeedMessages: '',
            liveFeedSessionId: null,
            supportedStates: [],
            supportedCountries: [],
            supportedTimezones: [],
            supportedBookingSystems: [],
            botsAllowedValue: 0,
            referralUrl: '',
            runningBotUrl: '',
            liveBotViewModalShow: false,
            liveBotName: '',
            liveBotSessionId: '',
            customerBrainTreeSubscriptionId: '',
            customerMainSubscriptionTypeId: '',
            monthlyBotRuns: 0,
            startedInteractiveBots: []
        };
    }
    componentDidMount() {
        const rootStore = this.props.rootStore;
        var autoStart = getParameterByName('start', window.location.href);
        if (autoStart) {
            switch (autoStart) {
                case 'builder':
                    this.setState({ activeKey: 'builder' }, () => {
                        this.getNextBots()
                    })
                    break;
                case 'botHistory':
                    var botId = getParameterByName('bid', window.location.href);
                    this.setState({ activeKey: 'botHistory', botHistory: [], botHistorySearchInput: botId }, () => {
                        this.getBotHistory();
                    })
                    break;
                case 'usersBots':
                    var userId = getParameterByName('uid', window.location.href);
                    this.setState({ activeKey: 'usersBots', userBots: [], userSearchBotInput: userId }, () => {
                        this.getUserBots();
                    })
                    break;
                case 'miniBots':
                    var miniType = getParameterByName('mini', window.location.href);
                    this.setState({ activeKey: 'miniBots', miniBots: [], miniSearchType: miniType }, () => {
                        this.getMiniBots();
                    })
                    break;
                case 'interactive':
                    this.setState({ activeKey: 'interactive', upcomingInteractive: [] }, () => {
                        this.getUpcomingInteractive();
                    })
                    break;
                case 'recording':
                    this.setState({ activeKey: 'recording', recording: [] }, () => {
                        this.getRecording();
                    })
                    break;
            }
        }
        else {
            this.handleTabs("home")
        }
    }
    handleTabs = (key) => {
        switch (key) {
            case "home":
                this.setState({ activeKey: key }, () => { });
                break;
            case "ticker":
                this.setState({ activeKey: key }, () => this.getUpcoming());
                break;
            case "interactive":
                this.setState({ activeKey: key }, () => this.getUpcomingInteractive());
                break;
            case "failed":
                this.setState({ activeKey: key }, () => this.getFailed());
                break;
            case "recording":
                this.setState({ activeKey: key }, () => this.getRecording());
                break;
            case "noResult":
                this.setState({ activeKey: key }, () => this.getNoResult());
                break;
            case "successful":
                this.setState({ activeKey: key }, () => this.getSuccess());
                break;
            case "usersBots":
                this.setState({ activeKey: key, userBots: [], userSearchBotInput: '' });
                break;
            case "botHistory":
                this.setState({ activeKey: key, botHistory: [], botHistorySearchInput: '' });
                break;
            case "builder":
                this.setState({ activeKey: key }, () => this.getNextBots());
                break;
            case "templates":
                this.setState({ activeKey: key }, () => this.getTemplateSeries());
                break;
            case "IPChecker":
                this.setState({ activeKey: key, ipTestResults: [], listOfIPAddresses: '', confirmId: '', confirmClass: '', confirmXpath: '', ipTestUrl: '' });
                break;
            case "running":
                this.setState({ activeKey: key }, () => this.getRunningBots());
                break;
            case "users":
                this.setState({ activeKey: key }, () => this.getLatestUsers());
                break;
            case "bites":
                this.setState({ activeKey: key }, () => this.getDailyBites());
                break;
            case "miniBots":
                this.setState({ activeKey: key, miniBots: [], miniSearchType: null }, () => this.getMiniBotTypes());
                break;
        }
    }
    getUpcoming = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/upcoming').then((response) => {
            const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
            var localE = {};
            var localN = {};
            if (response && response.data && response.data[0].upcomingElite)
                localE = groupByKey(response.data[0].upcomingElite, 'upcomingRunTimePST');

            if (response && response.data)
                localN = groupByKey(response.data, 'upcomingRunTimePST');
            this.setState({ upcoming: response.data, eliteGroups: localE, nonEliteGroups: localN }, () => { this.showIsLoading(false) });
            var date = new Date();
            // ApiClient.post('bot/service/completed/count', { endDate: (date.getMonth() + 1).toString() + "/" + date.getUTCDate() + "/" + date.getFullYear().toString(), startDate: (date.getMonth() + 1).toString() + "/1/" + date.getFullYear().toString() }).then((totalCount) => {
            //     this.setState({ totalMonthlyBotCount: totalCount.data }, () => {
            //     });
            // }).catch((error) => {
            //     alert(error.response.error)
            // });

        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getUpcomingInteractive = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/interactive/upcoming').then((response) => {
            const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
            var localN = {};
            if (response && response.data)
                localN = groupByKey(response.data, 'upcomingRunTimePST');
            this.setState({ upcomingInteractive: response.data, nonEliteGroups: localN, startedInteractiveBots: [] }, () => { this.showIsLoading(false) });

        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getFailed = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/recent/failure').then((response) => {
            this.setState({ failed: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getRecording = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/recording/report').then((response) => {
            this.setState({ recording: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getNoResult = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/recent/no-results').then((response) => {
            this.setState({ noResults: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getSuccess = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/recent/success').then((response) => {
            this.setState({ success: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getDailyBites = (searchId) => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/bites' + (!searchId ? '' : '/' + searchId)).then((response) => {
            this.setState({ bites: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    searchDailyBites = () => {
        this.getDailyBites(this.state.botBotSearchId);
    }
    getNextBots = () => {
        this.showIsLoading(true);
        if (access < 8) {
            ApiClient.get('bothtml/unlinked/assigned/all').then((response) => {
                this.setState({ next: response.data }, () => { this.showIsLoading(false) });
            }).catch((error) => {
                alert(error.response.error)
            });
        } else {
            ApiClient.get('bothtml/unlinked/all').then((response) => {
                this.setState({ next: response.data }, () => { this.showIsLoading(false) });
            }).catch((error) => {
                alert(error.response.error)
            });
        }
    }
    getTemplateSeries = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/config/templates/all').then((response) => {
            this.setState({ series: response.data.series, supportedSubscriptions: response.data.supportedSubscriptions }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    updateTemplateInformationFromNewDuplicate = (data) => {
        this.setState({ series: data.series, supportedSubscriptions: data.supportedSubscriptions });
    }
    handleOnDuplicateTemplateClick = (id, name) => {
        this.setState({ originalSeriesId: id, originalName: name }, () => {
            this.onShowDuplicateSeriesModal(true);
        })
    }
    onShowDuplicateSeriesModal = (show) => {
        this.setState({ showDuplicateSeriesModal: show })
    }
    getRunningBots = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/currently/running').then((response) => {
            this.setState({ running: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getLatestUsers = () => {
        this.showIsLoading(true);
        ApiClient.get('subscription/latest/users').then((response) => {
            this.setState({ latestUsers: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    onChangeUserBotInput = (e) => {
        this.setState({ userSearchBotInput: e.target.value });
    }
    getUserBots = () => {
        this.showIsLoading(true);
        if (this.state.userSearchBotInput.startsWith("http")) {
            ApiClient.get('bot/service/similar/' + encodeURIComponent(this.state.userSearchBotInput)).then((response) => {
                this.setState({ userBots: response.data }, () => { this.showIsLoading(false) });
            }).catch((error) => {
                alert(error.response.error)
            });
        }
        else {
            ApiClient.get('bot/service/user-bots/' + this.state.userSearchBotInput.toLowerCase()).then((response) => {
                this.setState({ userBots: response.data }, () => { this.showIsLoading(false) });
            }).catch((error) => {
                alert(error.response.error)
            });
        }
    }
    getMiniBotTypes = () => {
        this.showIsLoading(true);
        ApiClient.get('minibot/all').then((response) => {
            this.setState({ miniBotTypes: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getMiniBots = () => {
        this.showIsLoading(true);
        ApiClient.get('minibot/all/' + this.state.miniSearchType).then((response) => {
            this.setState({ miniBots: response.data }, () => {
                ApiClient.get('bot/supported/timezones').then((response1) => {
                    this.setState({ supportedTimezones: response1.data }, () => {
                        ApiClient.get('bot/service/supported/states').then((response2) => {
                            this.setState({ supportedStates: response2.data }, () => {
                                ApiClient.get('bot/service/supported/countries').then((response3) => {
                                    this.setState({ supportedCountries: response3.data }, () => {
                                        ApiClient.get('bot/service/supported/booking/systems').then((response4) => {
                                            this.setState({ supportedBookingSystems: response4.data }, () => {
                                                this.showIsLoading(false)
                                            });
                                        }).catch((error) => {
                                            alert(error.response.error)
                                        });
                                    });
                                }).catch((error) => {
                                    alert(error.response.error)
                                });
                            });
                        }).catch((error) => {
                            alert(error.response.error)
                        });
                    });
                }).catch((error) => {
                    alert(error.response.error)
                });

            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    onMiniBotListChange = (e) => {
        this.setState({ miniSearchType: e.target.text }, () => {
            this.getMiniBots();
        });
    }
    onConfirmDeleteMiniBot = (name, id) => {
        if (window.confirm("Are you sure you want to delete Mini Bot " + name + "? THIS CANNOT BE UNDONE!")) {
            ApiClient.post('minibot/delete/' + id).then((response) => {
                this.getMiniBots();
            }).catch((error) => {
                alert(error.response.error)
            });
        }
    }
    onPublicShareBotMenuClick = (url, name) => {
        this.setState({ miniBotName: name, publicShareUrl: url }, () => {
            this.onShowPublicShareModal(true);
        });
    }
    onShowPublicShareModal = (show) => {
        this.setState({ publicShareModalShow: show }, () => { if (!show) this.resetMiniBotData() })
    }
    onCopyButtonClick = () => {
        navigator.clipboard.writeText(this.state.publicShareUrl)
        this.setState({ publicShareCopyButtonText: 'Copied!' }, () => {
            setTimeout(
                () => this.setState({ publicShareCopyButtonText: 'Copy' }),
                3000
            );
        });
    }
    onHandleMasterBotIdChange = (e) => {
        this.setState({ miniBotMasterBotId: e.target.value })
    }
    onHandleMiniBotNameChange = (e) => {
        this.setState({ miniBotName: e.target.value })
    }
    onHandleCancellationHoursChange = (e) => {
        this.setState({ miniBotCancellationHours: e.target.value })
    }
    onHandleAdvanceReleaseDaysChange = (e) => {
        this.setState({ miniBotAdvanceReleaseDays: e.target.value })
    }
    changeMiniPricingType = (e) => {
        this.setState({ miniBotPricingType: e.target.value })
    }
    onchangeSupportedStates = (e) => {
        this.setState({ miniBotState: e.target.value })
    }
    onchangeSupportedCountries = (e) => {
        this.setState({ miniBotSupportedCountry: e.target.value })
    }
    onchangeSupportedTimezones = (e) => {
        this.setState({ miniBotTimeZoneId: e.target.value })
    }
    onHandleBookingSystemChange = (e) => {
        this.setState({ miniBotBookingSystem: e.target.value })
    }
    onHandleMiniCityChange = (e) => {
        this.setState({ miniBotCity: e.target.value })
    }
    onHandleMiniNotesChange = (e) => {
        this.setState({ miniBotNotes: e.target.value })
    }
    onHandleMiniPhoneChange = (e) => {
        this.setState({ miniBotPhoneNumber: e.target.value })
    }
    onHandleMiniStreetAddressChange = (e) => {
        this.setState({ miniBotStreetAddress: e.target.value })
    }
    onHandleMiniVersionChange = (e) => {
        this.setState({ miniBotVersion: e.target.value })
    }
    onHandleMiniZipCodeChange = (e) => {
        this.setState({ miniBotZipCode: e.target.value })
    }
    onHandleMiniWebsiteURLChange = (e) => {
        this.setState({ miniBotWebsiteUrl: e.target.value })
    }
    onTimeOfReleaseChange = (value) => {
        if (value && value.format('HH:mm:ss').length > 0) {
            this.setState({ timeOfRelease: value.format('HH:mm:ss') })
        }
    }
    onSaveMiniBot = () => {
        if (this.state.miniBotId) {
            ApiClient.post('minibot/update', {
                miniBotId: this.state.miniBotId, name: this.state.miniBotName, masterBotId: this.state.miniBotMasterBotId,
                cancellationHours: this.state.miniBotCancellationHours, advanceReleaseDays: this.state.miniBotAdvanceReleaseDays, pricingType: this.state.miniBotPricingType,
                timeOfRelease: this.state.timeOfRelease, bookingSystem: this.state.miniBotBookingSystem, version: this.state.miniBotVersion, notes: this.state.miniBotNotes,
                timeZoneId: this.state.miniBotTimeZoneId, phoneNumber: this.state.miniBotPhoneNumber, streetAddress: this.state.miniBotStreetAddress, city: this.state.miniBotCity,
                state: this.state.miniBotState, zipCode: this.state.miniBotZipCode, country: this.state.miniBotSupportedCountry, websiteURL: this.state.miniBotWebsiteUrl
            }).then((response) => {
                this.onShowCreateMiniBotModal(false);
            }).catch((error) => {
                alert(error.response.error)
            });
        }
        else {
            ApiClient.post('minibot/create', {
                miniBotType: this.state.miniSearchType,
                name: this.state.miniBotName, masterBotId: this.state.miniBotMasterBotId,
                cancellationHours: this.state.miniBotCancellationHours, advanceReleaseDays: this.state.miniBotAdvanceReleaseDays,
                pricingType: this.state.miniBotPricingType, timeOfRelease: this.state.timeOfRelease, notes: this.state.miniBotNotes
            }).then((response) => {
                this.onShowCreateMiniBotModal(false);
            }).catch((error) => {
                alert(error.response.error)
            });
        }

    }
    resetMiniBotData = () => {
        if (this.state.miniBotName)
            this.setState({
                miniBotId: null, miniBotMasterBotId: null, miniBotName: null, miniBotCancellationHours: 0, miniBotAdvanceReleaseDays: 0, miniBotPricingType: "STANDARD", timeOfRelease: null,
                miniBotBookingSystem: "NA", miniBotCity: null, miniBotCountry: null, miniBotNotes: null, miniBotPhoneNumber: null, miniBotState: null, miniBotStreetAddress: null, miniBotTimeZoneId: null,
                miniBotVersion: 1.0, miniBotZipCode: null, miniBotWebsiteUrl: null
            },
                () => this.getMiniBots());
    }
    showMiniBotEditModal = (miniName, miniId, miniCancelHours, miniAdvanceRelease, miniTimeOfRelease, miniMasterBotId, pricingType,
        miniBotBookingSystem, miniBotCity, miniBotCountry, miniBotNotes, miniBotPhoneNumber, miniBotState, miniBotStreetAddress, miniBotTimeZoneId, miniBotVersion,
        miniBotZipCode, miniBotWebsiteUrl) => {
        this.setState({
            miniBotId: miniId, miniBotMasterBotId: miniMasterBotId, miniBotName: miniName, miniBotCancellationHours: miniCancelHours,
            miniBotAdvanceReleaseDays: miniAdvanceRelease, timeOfRelease: miniTimeOfRelease, miniBotPricingType: pricingType,
            miniBotBookingSystem: miniBotBookingSystem, miniBotCity: miniBotCity, miniBotCountry: miniBotCountry, miniBotNotes: miniBotNotes, miniBotPhoneNumber: miniBotPhoneNumber,
            miniBotState: miniBotState, miniBotStreetAddress: miniBotStreetAddress, miniBotVersion: miniBotVersion,
            miniBotTimeZoneId: miniBotTimeZoneId, miniBotWebsiteUrl: miniBotWebsiteUrl, miniBotZipCode: miniBotZipCode
        }, () => {
            this.onShowCreateMiniBotModal(true);
        })
    }
    onShowCreateMiniBotModal = (show) => {
        this.setState({ editMiniBotModalShow: show }, () => { if (!show) this.resetMiniBotData() })
    }
    onChangeBotHistorySearchInput = (e) => {
        this.setState({ botHistorySearchInput: e.target.value });
    }
    onSearchUserEmailOrIdChange = (e) => {
        this.setState({ userEmailOrIdInput: e.target.value });
    }
    onBotBotSearchInputChange = (e) => {
        this.setState({ botBotSearchId: e.target.value });
    }
    getBotHistory = () => {
        this.showIsLoading(true);
        ApiClient.get('bot/service/recent/history/' + this.state.botHistorySearchInput).then((response) => {
            this.setState({ botHistory: response.data }, () => { this.showIsLoading(false) });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    startInteractiveBot = (botId) => {
        ApiClient.post('bot/that/schedule/interactive/' + botId).then((response) => {
            this.setState(prevState => ({
                startedInteractiveBots: [...prevState.startedInteractiveBots, botId]
            }))
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    interactionCompletedEarly = (botId) => {
        ApiClient.post('bot/interactive/complete/' + botId).then((response) => {
            this.getUpcomingInteractive();
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    searchUserEmailOrId = () => {
        this.showIsLoading(true);
        ApiClient.get('user/profile/' + this.state.userEmailOrIdInput).then((response) => {
            this.showIsLoading(false);
            if (response.data) {
                this.setState({
                    userEmailOrIdInput: '',
                    customerId: response.data.userId,
                    bze: response.data.bzE,
                    bzeTier: response.data.bzeTier,
                    customerSearchedName: response.data.name,
                    customerSearchedEmail: response.data.emailAddress,
                    customerSearchedSubscription: response.data.subscriptionName,
                    customerSignUpType: response.data.signupType,
                    customerSearchedProvider: response.data.subscriptionProvider,
                    customerSearchedStatus: response.data.subscriptionState,
                    customerSearchedExpiration: response.data.subscriptionExpirationPst,
                    customerBotsAllowedCount: response.data.botsAllowed,
                    referralUrl: response.data.referralUrl,
                    monthlyBotRuns: response.data.monthlyBotRuns,
                    customerBrainTreeSubscriptionId: response.data.brainTreeSubscriptionId,
                    customerMainSubscriptionTypeId: response.data.subscriptionTypeId

                }, () => { this.handleShowUserProfileModal(true) })
            }
        }).catch((error) => {
            this.showIsLoading(false);
            alert("User Not Found")
        });
    }
    onResetSubscriptionClick = () => {
        if (window.confirm("Are you sure you want to reset this customers subscription?")) {
            ApiClient.post('subscription/reset/' + this.state.customerSearchedEmail, {}).then((response) => {
                if (response.status == 200) {
                    this.setState({ userEmailOrIdInput: this.state.customerSearchedEmail }, () => {
                        this.handleShowUserProfileModal(false)
                        this.searchUserEmailOrId();
                    })
                }
            }).catch((error) => {
                alert("Error saving new Series")
            });
        }
    }
    onConvertAppleToEmail = () => {
        if (window.confirm("Are you sure you want to convert this user from Apple Sign in To Email Sign in?")) {
            ApiClient.post('user/password/convert/apple', { emailAddress: this.state.customerSearchedEmail }).then((response) => {
                if (response.status == 200) {
                    alert("Apple user is now converted. Check Bot-It support email to change password.")
                }
            }).catch((error) => {
                alert("Error converting apple user " + error)
            });
        }
    }
    showIsLoading = (show) => {
        this.setState({ isLoading: show });
    }
    onChangeSearchBotId = (e) => {
        this.setState({ botBuilderID: e.target.value })
    }
    goToBotBuilder = () => {
        if (this.state.botBuilderID)
            window.location = "/builder?bid=" + this.state.botBuilderID + "&linked=true";
    }
    timerComplete = () => {
        window.location.reload();
    }
    disableUserBots = (userId) => {
        if (window.confirm("Are you sure you want to disable all users Bots?")) {
            ApiClient.post('user/deactivate/all/' + userId).then((response) => {
                this.handleShowUserProfileModal(false); this.getLatestUsers();
            }).catch((error) => {
                alert(error.response.error)
            });
        }
    }
    deleteEmailQueue = (userId) => {
        if (window.confirm("Are you sure you want to delete users email queue?")) {
            ApiClient.post('user/delete/email/queue/' + userId).then((response) => {
                this.handleShowUserProfileModal(false); this.getLatestUsers();
            }).catch((error) => {
                alert(error.response.error)
            });
        }
    }
    deleteUser = (userId) => {
        if (window.confirm("Are you sure you want to delete this user & all their data? (ACTION CANNOT BE REVERSED)")) {
            ApiClient.post('user/delete/account/' + userId).then((response) => {
                this.handleShowUserProfileModal(false); this.getLatestUsers();
            }).catch((error) => {
                alert(error.response.error)
            });
        }
    }
    handleShowCreateSeriesModal = (show) => {
        this.setState({ showCreateSeriesModal: show })
    }
    handleShowUserProfileModal = (show) => {
        this.setState({ showUserProfileModal: show })
    }
    onHandleSeriesNameChange = (e) => {
        this.setState({ newSeriesName: e.target.value })
    }
    onHandleSeriesTTCEstimateChange = (e) => {
        this.setState({ newSeriesTTCEstimate: e.target.value })
    }
    onHandleSeriesMinSubscriptionChange = (value) => {
        this.setState({ newSeriesSelectedMinSubscription: value })
    }
    onSaveSeriesClick = () => {
        ApiClient.post('bot/service/create/series', { name: this.state.newSeriesName, ttcEstimate: this.state.newSeriesTTCEstimate, minSubscriptionRequirement: this.state.newSeriesSelectedMinSubscription }).then((response) => {
            if (response.status == 200) {
                window.location = "/template?seriesId=" + response.data;
            }
        }).catch((error) => {
            alert("Error saving new Series")
        });
    }
    getNextSiblings = (elem, filter) => {
        var sibs = [];
        while (elem = elem.nextSibling) {
            if (elem.nodeType === 3) continue; // text node
            if (!filter || filter(elem)) sibs.push(elem);
        }
        return sibs;
    }
    expandBusinessEliteScheduleClick = (e) => {
        const sibs = this.getNextSiblings(e.currentTarget);

        e.currentTarget.className.indexOf("accordionNotExpanded") > -1 ? this.openBusinessElite(e) : this.collapseBusinessElite(e);
        sibs.forEach((item) => {
            item.className.indexOf("collapse show") > -1 ? item.classList.remove("show") : item.classList.add("show");
        })

    };
    collapseBusinessElite = (e) => {
        e.currentTarget.classList.remove("accordionExpanded");
        e.currentTarget.classList.add("accordionNotExpanded");
    }
    openBusinessElite = (e) => {
        e.currentTarget.classList.remove("accordionNotExpanded");
        e.currentTarget.classList.add("accordionExpanded");
    }
    handleBotsAllowChange = (e) => {
        this.setState({ customerBotsAllowedCount: parseInt(e.target.value) })
    }
    handleUserBrainTreeSubChange = (e) => {
        this.setState({ customerBrainTreeSubscriptionId: e.target.value })
    }
    handleUserSubscriptionTypeIdChange = (e) => {
        this.setState({ customerMainSubscriptionTypeId: e.target.value })
    }
    updateBotsAllowed = (id) => {
        ApiClient.post('user/update/bot/count/' + id + '/' + this.state.customerBotsAllowedCount, {}).then((response) => {
            if (response.status == 200) {
                this.handleShowUserProfileModal(false); this.getLatestUsers();
            }
        }).catch((error) => {
            alert("Error saving new Series")
        });
    }
    updateUserSubscriptionType = (id) => {
        ApiClient.post('subscription/update/type', { userId: id, subscriptionTypeId: this.state.customerMainSubscriptionTypeId, brainTreeId: this.state.customerBrainTreeSubscriptionId }).then((response) => {
            if (response.status == 200) {
                this.handleShowUserProfileModal(false); this.getLatestUsers();
            }
        }).catch((error) => {
            alert("Error saving new Series")
        });
    }
    onClickViewRunningBot = (sessionId, botName) => {
        this.setState({ liveSessionId: sessionId, runningBotUrl: config.api + 'ui/view/' + sessionId, liveBotName: botName }, () => this.onShowLiveBotViewModal(true))
    }
    onShowLiveBotViewModal = (show) => {
        this.setState({ liveBotViewModalShow: show, });
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container fluid>
                {/* {this.state.timerRefresh > 0 &&
                    <h3 style={{ textAlign: 'center' }}>Auto Refresh: <Countdown date={Date.now() + this.state.timerRefresh} zeroPadTime={2} renderer={renderer} onComplete={this.timerComplete} /></h3>
                } */}

                <Tabs
                    id="controlled-tab-example"
                    className="mb-3 justify-content-center"
                    activeKey={this.state.activeKey}
                    onSelect={(k) => this.handleTabs(k)}
                >
                    <Tab eventKey="home" title="Home">
                        <Row style={{ marginTop: '130px' }}>
                            <Col className='d-flex justify-content-center align-items-center'>
                                <h1>Welcome to the Bot Ticker. Click a menu item to get started!</h1>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '30px' }}>
                            <Col className='d-flex justify-content-center align-items-center'>

                                <img src={happyBot} />

                            </Col>
                        </Row>
                    </Tab>
                    {access > 3 &&
                        <Tab eventKey="ticker" title={this.state.upcoming && this.state.upcoming.length > 0 ? "Bot Ticker (" + this.state.upcoming.length + ")" : "Bot Ticker"}>
                            {this.state.totalMonthlyBotCount > 0 &&
                                <h3>Monthly Bots Ran: {this.state.totalMonthlyBotCount.toLocaleString("en-US")}</h3>}
                            <Table responsive class="table table-condensed" style={{ "border-collapse": "collapse;" }}>
                                <thead>
                                    <tr>
                                        {this.state.upComingHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.upcoming.map((bot, index) => (
                                        bot.upcomingElite ? (<React.Fragment>
                                            <tr className='accordionNotExpanded' onClick={this.expandBusinessEliteScheduleClick} ><Button variant='link'>{bot.userName}</Button> </tr>
                                            {bot.upcomingElite.map((elite, idx) => (
                                                <React.Fragment>
                                                    <tr className="collapse">
                                                        <td key={idx}><a target="_blank" href={"https://app.botit.com/create?bid=" + elite.botId}>{elite.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + elite.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                                        <td key={idx}><a target={"_blank"} href={"/builder?bid=" + elite.botId + "&linked=true"}>{elite.botName}<img hidden={!elite.recordBot} src={videoCamera} style={{ marginLeft: '5px' }} width="16" height="16" /><img hidden={!elite.captchaSolver} src={captcha} style={{ marginLeft: '5px' }} width="16" height="16" /></a></td>
                                                        <td key={idx}><a href={"/?start=usersBots&uid=" + elite.userId} target="_blank">{elite.userName}</a></td>
                                                        <td key={idx}>{elite.proxyProvider}: {elite.proxyGroup} {elite.isLocallyRunning ? <img src={localServer} width="16" height="16" /> : ""}</td>
                                                        <td key={idx}>{elite.bookingDate}</td>
                                                        <td key={idx}>{elite.searchFilter && elite.searchFilter.length > 0 && elite.searchFilter.map((filter) => {
                                                            return <div>
                                                                <span>{filter}</span>
                                                            </div>
                                                        })}</td>
                                                        <td key={idx} style={{ fontSize: "14px" }} ><Countdown daysInHours={true} date={Date.now() + elite.startsIn} /><span>{" (" + elite.upcomingRunTimePST + ")"}</span></td>
                                                    </tr></React.Fragment>

                                            ))}



                                        </React.Fragment>) : (
                                            <React.Fragment>
                                                <tr> {this.state.upcoming && this.state.upcoming[index - 1] && this.state.upcoming[index - 1].upcomingRunTimePST != bot.upcomingRunTimePST && this.state.nonEliteGroups && this.state.nonEliteGroups[bot.upcomingRunTimePST] &&
                                                    <td><b>{bot.upcomingRunTimePST + " (" + (bot.upcomingRunTimePST.includes(":00 ") || bot.upcomingRunTimePST.includes(":30 ") ?

                                                        (this.state.nonEliteGroups[bot.upcomingRunTimePST].length + 25 +
                                                            this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("2,1") }).length +
                                                            (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("6,1") }).length * 5) +
                                                            (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("9,1") }).length * 8)) :
                                                        this.state.nonEliteGroups[bot.upcomingRunTimePST].length +
                                                        this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("2,1") }).length +
                                                        (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("6,1") }).length * 5) +
                                                        (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("9,1") }).length * 8)) +
                                                        ")"}
                                                    </b></td>}</tr>
                                                <tr>
                                                    <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.botId}>{bot.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                                    <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.botId + "&linked=true"}>{bot.botName}<img hidden={!bot.recordBot} src={videoCamera} style={{ marginLeft: '5px' }} width="16" height="16" /><img hidden={!bot.captchaSolver} src={captcha} style={{ marginLeft: '5px' }} width="16" height="16" /></a></td>
                                                    <td key={index}><a href={"/?start=usersBots&uid=" + bot.userId} target="_blank">{bot.userName}</a></td>
                                                    <td key={index}>{bot.proxyProvider}:{bot.proxyGroup}{bot.isLocallyRunning ? <img src={localServer} width="16" height="16" /> : ""}</td>
                                                    <td key={index} style={{ color: bot.bookingDateIncorrect ? 'red' : 'black' }}>{bot.bookingDate}</td>
                                                    <td key={index}>{bot.searchFilter && bot.searchFilter.length > 0 && bot.searchFilter.map((filter) => {
                                                        return <div>
                                                            <span>{filter}</span>
                                                        </div>
                                                    })}</td>
                                                    <td key={index} style={{ fontSize: "14px" }} ><Countdown daysInHours={true} date={Date.now() + bot.startsIn} /><span>{" (" + bot.upcomingRunTimePST + ")"}</span></td>
                                                </tr></React.Fragment>)

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {access > 30 &&
                        <Tab eventKey="interactive" title={this.state.upcomingInteractive && this.state.upcomingInteractive.length > 0 ? "Interactive (" + this.state.upcomingInteractive.length + ")" : "Interactive"}>
                            <Table responsive class="table table-condensed" style={{ "border-collapse": "collapse;" }}>
                                <thead>
                                    <tr>
                                        {this.state.upcomingInteractiveHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.upcomingInteractive.map((bot, index) => (
                                        <React.Fragment>
                                            <tr> {this.state.upcomingInteractive && this.state.upcomingInteractive[index - 1] && this.state.upcomingInteractive[index - 1].upcomingRunTimePST != bot.upcomingRunTimePST && this.state.nonEliteGroups && this.state.nonEliteGroups[bot.upcomingRunTimePST] &&
                                                <td><b>{bot.upcomingRunTimePST + " (" + (bot.upcomingRunTimePST.includes(":00 ") || bot.upcomingRunTimePST.includes(":30 ") ?

                                                    (this.state.nonEliteGroups[bot.upcomingRunTimePST].length + 25 +
                                                        this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("2,1") }).length +
                                                        (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("6,1") }).length * 5) +
                                                        (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("9,1") }).length * 8)) :
                                                    this.state.nonEliteGroups[bot.upcomingRunTimePST].length +
                                                    this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("2,1") }).length +
                                                    (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("6,1") }).length * 5) +
                                                    (this.state.nonEliteGroups[bot.upcomingRunTimePST].filter(x => { return x.botName.includes("9,1") }).length * 8)) +
                                                    ")"}
                                                </b></td>}</tr>
                                            <tr>
                                                <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.botId}>{bot.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                                <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.botId + "&linked=true"}>{bot.botName}<img hidden={!bot.recordBot} src={videoCamera} style={{ marginLeft: '5px' }} width="16" height="16" /><img hidden={!bot.captchaSolver} src={captcha} style={{ marginLeft: '5px' }} width="16" height="16" /></a></td>
                                                <td key={index}><a href={"/?start=usersBots&uid=" + bot.userId} target="_blank">{bot.userName}</a></td>
                                                <td key={index}>{bot.mainUrl}</td>
                                                <td key={index} style={{ whiteSpace: 'pre-wrap' }}>{bot.interactiveInstructions}</td>
                                                <td key={index} style={{ fontSize: "14px" }} ><Countdown daysInHours={true} date={Date.now() + bot.startsIn} /><span>{" (" + bot.upcomingRunTimePST + ")"}</span></td>
                                                <td key={index}>{bot.interactionBotStartedBy}</td>
                                                <td key={index}>
                                                    <Button variant='success' disabled={this.state.startedInteractiveBots.includes(bot.botId) || bot.interactionReady} onClick={() => this.startInteractiveBot(bot.botId)}>{this.state.startedInteractiveBots.includes(bot.botId) || bot.interactionReady ? "Ready" : "Start"}</Button>
                                                    {this.state.startedInteractiveBots.includes(bot.botId) || bot.interactionReady ? <Button onClick={() => this.interactionCompletedEarly(bot.botId)} style={{ marginLeft: "3px" }} variant="link">Done</Button> : ""}
                                                </td>
                                            </tr></React.Fragment>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {access > 3 &&
                        <Tab eventKey="failed" title="Failed Bots">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.failedHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.failed.map((bot, index) => (
                                        <tr>
                                            <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.botId}>{bot.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                            <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.botId + "&linked=true"}>{bot.botName}</a></td>
                                            <td key={index}><a href={"mailto:" + bot.userEmailAddress}>{bot.userName}</a></td>
                                            <td key={index}><a href={"/?start=usersBots&uid=" + bot.userId} target="_blank">{bot.message}</a></td>
                                            <td key={index}>{bot.startTime}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {access > 70 &&
                        <Tab eventKey="noResult" title="No Result Bots" disabled={true}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.noResultHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.noResults.map((bot, index) => (
                                        <tr>
                                            <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.botId}>{bot.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                            <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.botId + "&linked=true"}>{bot.botName}</a></td>
                                            <td key={index}><a href={"mailto:" + bot.userEmailAddress}>{bot.userName}</a></td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {access > 7 &&
                        <Tab eventKey="successful" title="Successful Bots">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.successHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.success.map((bot, index) => (
                                        <tr>
                                            <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.botId}>{bot.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                            <td key={index}>{bot.botName}</td>
                                            <td key={index}>{bot.userName}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {(access > 7 || access == 2 || access == 4) &&
                        <Tab eventKey="bites" title="Bites">
                            <Row>
                                <Col xs={9}>
                                    <Form.Group className="mb-3" controlId="botInfo">
                                        <Form.Control type="text" placeholder={"Enter Bot ID"} autoComplete={"off"} onChange={this.onBotBotSearchInputChange} value={this.state.botBotSearchId} />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Button variant={"secondary"} onClick={this.searchDailyBites}>Search</Button>
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.biteHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.bites.map((bot, index) => (
                                        <tr>
                                            <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.id}>{bot.id}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.id}><img src={historyScroll} width="16" height="16" /></a></td>
                                            <td key={index}>{bot.date}</td>
                                            <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.id + "&linked=true"}>{bot.botName}</a></td>
                                            <td key={index}>{bot.searchFilter}</td>
                                            <td key={index}>{bot.startTime}</td>
                                            <td key={index}>{bot.itemClickTime}</td>
                                            <td key={index}>{bot.clickedItem}</td>
                                            <td key={index}>{bot.stepsCompleted}</td>
                                            <td key={index}>{bot.status}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {access > 3 &&
                        <Tab eventKey="recording" title="Recorded Bots">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.recordingHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.recording.map((bot, index) => (
                                        <tr>
                                            <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.id}>{bot.id}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.id}><img src={historyScroll} width="16" height="16" /></a></td>
                                            <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.id + "&linked=true"}>{bot.name}</a></td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {access > 3 &&
                        <Tab eventKey="IPChecker" title="IP Check">
                            <Row>
                                <Col xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <h2>Welcome to the IP Tester</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Formik
                                        initialValues={{
                                            ipAddresses: this.state.listOfIPAddresses,
                                            url: this.state.ipTestUrl,
                                            confirmId: this.state.confirmId,
                                            confirmClass: this.state.confirmClass,
                                            confirmXpath: this.state.confirmXpath,
                                        }}
                                        onSubmit={(values, actions) => {
                                            if (!values.confirmId && !values.confirmClass && !values.confirmXpath) {
                                                window.alert('Please enter 1 confirm element to run test.');
                                                return;
                                            }
                                            let myuuid = (Math.random() + 1).toString(36).substring(7);
                                            const newConnection = new HubConnectionBuilder()
                                                .withUrl(config.api + this.state.verifyIPAddress + myuuid)
                                                .withAutomaticReconnect()
                                                .build();
                                            newConnection.start()
                                                .then(() => {
                                                    this.setState({ liveFeedStatus: "(Testing In Progress...)" }, () => {
                                                        ApiClient.post('bot/that/ip/test', {
                                                            ipAddresses: values.ipAddresses, url: values.url, confirmXpath: values.confirmXpath,
                                                            confirmClass: values.confirmClass, confirmId: values.confirmId,
                                                            sessionId: myuuid
                                                        }).then((response) => {
                                                            actions.setSubmitting(false);
                                                            this.setState({ ipTestResults: response.data, liveFeedMessages: '', listOfIPAddresses: '', confirmId: '', confirmClass: '', confirmXpath: '', ipTestUrl: '', liveSessionId: myuuid });
                                                        }).catch((error) => {
                                                            alert(error.response.error)
                                                        });
                                                        newConnection.on('ReceiveMessage', message => {
                                                            if (this.state.liveFeedMessages) {
                                                                this.setState({ liveFeedMessages: this.state.liveFeedMessages + "\n" + message.message })
                                                            }
                                                            else
                                                                this.setState({ liveFeedMessages: "\n" + message.message })

                                                            switch (message.hubMessageType) {
                                                                case "VERIFYCOMPLETE":
                                                                    newConnection.stop();
                                                                    this.setState({ liveFeedStatus: "(Disconnected)" });
                                                                    break;
                                                                case "VERIFYFAILED":
                                                                case "NO_ID":
                                                                    newConnection.stop();
                                                                    this.setState({ allSavedData: [], liveFeedStatus: "(Disconnected)", syncButtonText: "Sync All HTML", isSyncButtonDisabled: false });
                                                                    break;
                                                            }
                                                        });
                                                    })

                                                })
                                                .catch(e => console.log('Connection failed: ', e));
                                        }}
                                    >
                                        {formik => (
                                            <div>
                                                <Form onSubmit={formik.handleSubmit}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="ipAddresses">
                                                                <Form.Label>Enter IP Addresses (separate by comma)</Form.Label>
                                                                <Form.Control as="textarea" rows={2} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.ipAddresses} required />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <Form.Group className="mb-3" controlId="url">
                                                                <Form.Label>Website URL</Form.Label>
                                                                <Form.Control type="text" placeholder="Test IP's on https://golf-website.com" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.url} required />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="confirmId">
                                                                <Form.Label>Confirm ID Element</Form.Label>
                                                                <Form.Control type="text" placeholder="Check Element by ID" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmId} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="confirmClass">
                                                                <Form.Label>Confirm Class Element</Form.Label>
                                                                <Form.Control type="text" placeholder="Check Element by Class" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmClass} block />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="confirmXpath">
                                                                <Form.Label>Confirm Xpath Element</Form.Label>
                                                                <Form.Control type="text" placeholder="Confirm Element By Xpath" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmXpath} block />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ textAlign: 'center' }}>
                                                        <Col lg={12}>
                                                            <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Running Test...." : "Start Test"}</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="liveFeed">
                                        <Form.Label>IP Verify Live Feed <span>{this.state.liveFeedStatus}</span></Form.Label>
                                        <div style={{ backgroundColor: 'white', margin: '30px 0', whiteSpace: 'pre-wrap', height: '150px', width: '470', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
                                            <span style={{ color: 'black' }}>{this.state.liveFeedMessages}</span>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                    }
                    {
                        (access > 7 || access == 2 || access == 3 || access == 4) &&
                        <Tab eventKey="running" title="Live Bots">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.runningHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.running.map((bot, index) => (
                                        bot.liveEliteBots ? (<React.Fragment><tr className='accordionNotExpanded' onClick={this.expandBusinessEliteScheduleClick} ><Button variant='link'>{bot.userName}</Button> </tr>
                                            {bot.liveEliteBots.map((elite, idx) => (
                                                <tr className="collapse">
                                                    <td key={idx}><a target="_blank" href={"https://app.botit.com/create?bid=" + elite.botId}>{elite.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + elite.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                                    <td key={idx}><a target={"_blank"} href={"/builder?bid=" + elite.botId + "&linked=true"}>{elite.botName}</a></td>
                                                    <td key={idx}><Button variant='link' onClick={() => this.onClickViewRunningBot(elite.liveSessionId, elite.botName)} href='#'>{elite.liveSessionId}</Button></td>
                                                    <td key={idx}>{elite.scheduleId}</td>
                                                    <td key={idx}>{elite.upcomingRunningTime}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>) :
                                            (<tr>
                                                <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.botId}>{bot.botId}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.botId}><img src={historyScroll} width="16" height="16" /></a></td>
                                                <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.botId + "&linked=true"}>{bot.botName}</a></td>
                                                <td key={index}><Button variant='link' onClick={() => this.onClickViewRunningBot(bot.liveSessionId, bot.botName)} href='#'>{bot.liveSessionId}</Button></td>
                                                <td key={index}>{bot.scheduleId}</td>
                                                <td key={index}>{bot.upcomingRunningTime}</td>
                                            </tr>)
                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {
                        (access > 7 || access == 2 || access == 3) &&
                        <Tab eventKey="users" title="Users">
                            {access > 7 &&
                                <Row>
                                    <Col xs={9}>
                                        <Form.Group className="mb-3" controlId="botinfo">
                                            <Form.Control type="text" placeholder={"Search Customer Profile by Email or ID"} autoComplete={"off"} onChange={this.onSearchUserEmailOrIdChange} value={this.state.userEmailOrIdInput} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                        <Button variant={"secondary"} onClick={this.searchUserEmailOrId}>Search</Button>
                                    </Col>
                                </Row>
                            }
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.latestUserHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.latestUsers.map((user, index) => (
                                        <tr>
                                            <td key={index}>{user.name}</td>
                                            <td key={index}><a href={"mailto:" + user.emailAddress}>{user.emailAddress}</a></td>
                                            <td key={index}>{user.id}</td>
                                            <td key={index}>{user.subscriptionType}</td>
                                            <td key={index}>{user.signUpType}</td>
                                            <td key={index}>{user.registrationDate}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {
                        access > 3 &&
                        <Tab eventKey="botHistory" title="Bot History">
                            <Row>
                                <Col xs={9}>
                                    <Form.Group className="mb-3" controlId="botInfo">
                                        <Form.Control type="text" placeholder={"Enter Bot ID"} autoComplete={"off"} onChange={this.onChangeBotHistorySearchInput} value={this.state.botHistorySearchInput} />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Button variant={"secondary"} onClick={this.getBotHistory}>Search</Button>
                                </Col>
                            </Row>
                            {this.state.botHistory && this.state.botHistory.length > 0 &&
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            {this.state.botHistoryHeaders.map((name, index) => (
                                                <th key={index}>{name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.botHistory.map((history, index) => (
                                            <tr>
                                                <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + history.botId}>{history.trackCounter ? history.postId + " (" + history.trackCounter + ")" : history.postId}</a></td>
                                                <td key={index}>{history.startSearchTime && history.startSearchTime.length > 0 && history.startSearchTime.map((item, index) => {
                                                    return <div>
                                                        <Row>
                                                            <Col>
                                                                <span>{index + 1}. {item}</span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                })

                                                }</td>
                                                <td key={index}>{history.searchFilter}</td>
                                                <td key={index}><a target={"_blank"} href={"/builder?bid=" + history.botId + "&linked=true"}>{history.state == 0 ? "Success: " : "Failed: "} {history.proxyIPAddress}</a></td>
                                                <td key={index}>{history.errorMessage}</td>
                                                <td key={index} >{history.startTime} <br /> {history.timeStamp + "(" + (Math.trunc(history.duration)) + ")"}</td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </Table>
                            }
                        </Tab>
                    }
                    {
                        access > 3 &&
                        <Tab eventKey="usersBots" title="Search Bots">
                            <Row>
                                <Col xs={9}>
                                    <Form.Group className="mb-3" controlId="userInfo">
                                        <Form.Control type="text" placeholder={"Enter Email Address, User ID or Url"} autoComplete={"off"} onChange={this.onChangeUserBotInput} value={this.state.userSearchBotInput} />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Button variant={"secondary"} onClick={this.getUserBots}>Search</Button>
                                </Col>
                            </Row>
                            {this.state.userBots && this.state.userBots.length > 0 &&
                                <div>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                {this.state.userBotsHeader.map((name, index) => (
                                                    <th key={index}>{name}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.userBots.map((bot, index) => (
                                                <tr>
                                                    <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.id}>{bot.id}</a>&nbsp;<a target={"_blank"} href={"/?start=botHistory&bid=" + bot.id}><img src={historyScroll} width="16" height="16" /></a></td>
                                                    {bot.userEmail ?
                                                        (<td key={index}><a href={"mailto:" + bot.userEmail}>{bot.userId}</a></td>) : (<td key={index}><span>{bot.userId}</span></td>)
                                                    }
                                                    <td key={index}>{bot.upcomingRunTime}</td>
                                                    <td key={index}>{bot.searchFilter && bot.searchFilter.length > 0 && bot.searchFilter.map((filter) => {
                                                        return <div>
                                                            <span>{filter}</span>
                                                        </div>
                                                    })}</td>

                                                    <td key={index}><a href={bot.isActive && (!bot.isPaused || bot.isPaused) ? "/builder?bid=" + bot.id + "&linked=true" : "/builder?bid=" + bot.id} target="_blank">{bot.name}<img hidden={!bot.recordBot} src={videoCamera} style={{ marginLeft: '5px' }} width="16" height="16" /><img hidden={!bot.captchaSolver} src={captcha} style={{ marginLeft: '5px' }} width="16" height="16" /><img hidden={!bot.isLocallyRunning} src={localServer} style={{ marginLeft: '5px' }} width="16" height="16" /></a></td>
                                                    <td key={index}>{bot.bookingDate}</td>
                                                    <td key={index}>{bot.isActive && !bot.isPaused ? "Live" : bot.isPaused ? "Paused" : "Inactive"}</td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </Tab>
                    }
                    {
                        access > 7 &&
                        <Tab eventKey="templates" title="Templates">
                            <Row>
                                <Col xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <Button variant={"secondary"} onClick={() => this.handleShowCreateSeriesModal(true)}>Create New Series</Button>
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.seriesHeader.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.series.map((series, index) => (
                                        <tr>
                                            <td key={index}><span>{series.seriesId}</span></td>
                                            <td key={index}><a href={"/template?seriesId=" + series.seriesId}>{series.name}</a></td>
                                            <td key={index}><span>{series.dateCreated}</span></td>
                                            <td key={index}>{series.questionCount}</td>
                                            <td key={index + 1}><Button variant='link' onClick={() => this.handleOnDuplicateTemplateClick(series.seriesId, series.name)}>Duplicate</Button></td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {
                        (access > 7 || access == 2 || access == 4) &&
                        <Tab eventKey="miniBots" title="Mini Bots">
                            <Row>
                                <Col xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                            {this.state.miniSearchType ? this.state.miniSearchType : "Select Mini Bot Type"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {this.state.miniBotTypes.map((typ) => (
                                                <Dropdown.Item onClick={this.onMiniBotListChange}>{typ}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {access > 3 && this.state.miniSearchType && <Button variant={"link"} onClick={() => this.onShowCreateMiniBotModal(true)}>New {this.state.miniSearchType} Mini Bot</Button>}
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.miniBotHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.miniBots.map((mini, index) => (
                                        <tr>
                                            <td key={index}>{(index + 1) + ". " + mini.name + " "} {(mini.isComplete ? <img width={'16px'} height={'16px'} src={greencheck} /> : "")}</td>
                                            <td key={index}>{mini.advanceReleaseDays}</td>
                                            <td key={index}>{mini.timeOfRelease}</td>
                                            <td key={index}>{mini.cancellationHours}</td>
                                            <td key={index}>{this.state.miniSearchType}</td>
                                            <td key={index}> <NavDropdown title="Manage" id="nav-dropdown">
                                                {access > 3 && <NavDropdown.Item onClick={() => {
                                                    this.showMiniBotEditModal(mini.name, mini.id, mini.cancellationHours, mini.advanceReleaseDays, mini.timeOfRelease,
                                                        mini.masterBotId, mini.pricingType, mini.bookingSystem, mini.city, mini.country, mini.notes, mini.phoneNumber, mini.state, mini.streetAddress,
                                                        mini.timeZoneId, mini.version, mini.zipCode, mini.websiteURL)
                                                }} eventKey="4.1">Edit</NavDropdown.Item>}
                                                {<NavDropdown.Item onClick={() => this.onPublicShareBotMenuClick(mini.botShareUrl, mini.name)} eventKey="4.2">Get Share Link</NavDropdown.Item>}
                                                {access > 7 && <NavDropdown.Item onClick={() => { this.onConfirmDeleteMiniBot(mini.name, mini.id) }} eventKey="4.3">Delete</NavDropdown.Item>}
                                            </NavDropdown></td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                    {
                        access > 3 &&
                        <Tab eventKey="builder" title="Need Configuration">
                            <Row>
                                <Col xs={9}>
                                    <Form.Group className="mb-3" controlId="botinfo">
                                        <Form.Control type="text" placeholder={"Enter Bot ID"} autoComplete={"off"} onChange={this.onChangeSearchBotId} value={this.state.botBuilderID} />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Button variant={"secondary"} onClick={this.goToBotBuilder}>Go</Button>
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.nextHeaders.map((name, index) => (
                                            <th key={index}>{name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.next.map((bot, index) => (
                                        <tr>
                                            <td key={index}><a target="_blank" href={"https://app.botit.com/create?bid=" + bot.botId}>{bot.botId}</a></td>
                                            <td key={index}><a target={"_blank"} href={"/builder?bid=" + bot.botId}>{bot.botName}</a></td>
                                            <td key={index}><a href={"mailto:" + bot.userEmailAddress}>{bot.userId}</a></td>
                                            <td key={index}>{bot.dateCreated}</td>
                                            <td key={index}>{bot.assignedTo && bot.assignedTo.assignedUserFullName ? bot.assignedTo.assignedUserFullName : ""}</td>
                                            <td key={index} style={bot.assignedTo && bot.assignedTo.priorityLevel == 2 ? { color: 'red' } : bot.assignedTo && bot.assignedTo.priorityLevel == 1 ? { color: 'green' } : { color: 'blue' }}>{bot.assignedTo && bot.assignedTo.priorityLevel == 0 ? "Low" : bot.assignedTo && bot.assignedTo.priorityLevel == 1 ? "Medium" : bot.assignedTo && bot.assignedTo.priorityLevel == 2 ? "High!" : "Low"}</td>
                                            <td key={index}>{bot.assignedTo && bot.assignedTo.dueDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    }
                </Tabs >
                <LoadingModal
                    show={this.state.isLoading}
                    onHide={() => this.showIsLoading(false)}
                    backdrop={'static'}
                    keyboard={false}
                />
                <CreateSeries
                    show={this.state.showCreateSeriesModal}
                    onHide={() => this.handleShowCreateSeriesModal(false)}
                    supportedSubscriptions={this.state.supportedSubscriptions}
                    newSeriesName={this.state.newSeriesName}
                    newSeriesTTCEstimate={this.state.newSeriesTTCEstimate}
                    newSeriesSelectedMinSubscription={this.state.newSeriesSelectedMinSubscription}
                    onHandleSeriesNameChange={this.onHandleSeriesNameChange}
                    onHandleSeriesTTCEstimateChange={this.onHandleSeriesTTCEstimateChange}
                    onHandleSeriesMinSubscriptionChange={this.onHandleSeriesMinSubscriptionChange}
                    onSaveSeriesClick={this.onSaveSeriesClick}
                />
                <CustomerProfileModal
                    show={this.state.showUserProfileModal}
                    onHide={() => this.handleShowUserProfileModal(false)}
                    name={this.state.customerSearchedName}
                    id={this.state.customerId}
                    bze={this.state.bze}
                    bzeTier={this.state.bzeTier}
                    customerSignUpType={this.state.customerSignUpType}
                    email={this.state.customerSearchedEmail}
                    subscription={this.state.customerSearchedSubscription}
                    provider={this.state.customerSearchedProvider}
                    status={this.state.customerSearchedStatus}
                    expiration={this.state.customerSearchedExpiration}
                    referralUrl={this.state.referralUrl}
                    customerBotsAllowedCount={this.state.customerBotsAllowedCount}
                    customerBrainTreeSubscriptionId={this.state.customerBrainTreeSubscriptionId}
                    customerMainSubscriptionTypeId={this.state.customerMainSubscriptionTypeId}
                    monthlyBotRuns={this.state.monthlyBotRuns}
                    onResetSubscriptionClick={() => this.onResetSubscriptionClick}
                    onConvertAppleToEmail={() => this.onConvertAppleToEmail}
                    disableUserBots={this.disableUserBots}
                    deleteUser={this.deleteUser}
                    deleteEmailQueue={this.deleteEmailQueue}
                    handleBotsAllowChange={this.handleBotsAllowChange}
                    handleUserBrainTreeSubChange={this.handleUserBrainTreeSubChange}
                    handleUserSubscriptionTypeIdChange={this.handleUserSubscriptionTypeIdChange}
                    updateBotsAllowed={this.updateBotsAllowed}
                    updateUserSubscriptionType={this.updateUserSubscriptionType}
                />
                <PublicShareBotModal
                    show={this.state.publicShareModalShow}
                    onHide={() => this.onShowPublicShareModal(false)}
                    botName={this.state.miniBotName}
                    shareUrl={this.state.publicShareUrl}
                    copyButtonText={this.state.publicShareCopyButtonText}
                    onCopyButtonClick={this.onCopyButtonClick}
                />
                <LiveBotRunView
                    show={this.state.liveBotViewModalShow}
                    onHide={() => this.onShowLiveBotViewModal(false)}
                    botName={this.state.liveBotName}
                    fullscreen={true}
                    runningBotUrl={this.state.runningBotUrl}
                />
                <CreateMiniBotModal
                    show={this.state.editMiniBotModalShow}
                    onHide={() => this.onShowCreateMiniBotModal(false)}
                    miniBotId={this.state.miniBotId}
                    miniBotMasterBotId={this.state.miniBotMasterBotId}
                    miniBotName={this.state.miniBotName}
                    miniBotCancellationHours={this.state.miniBotCancellationHours}
                    miniBotAdvanceReleaseDays={this.state.miniBotAdvanceReleaseDays}
                    pricingType={this.state.miniBotPricingType}
                    changeMiniPricingType={this.changeMiniPricingType}
                    timeOfRelease={this.state.timeOfRelease}
                    onHandleMasterBotIdChange={this.onHandleMasterBotIdChange}
                    onHandleMiniBotNameChange={this.onHandleMiniBotNameChange}
                    onHandleCancellationHoursChange={this.onHandleCancellationHoursChange}
                    onHandleAdvanceReleaseDaysChange={this.onHandleAdvanceReleaseDaysChange}
                    onTimeOfReleaseChange={this.onTimeOfReleaseChange}
                    onSaveMiniBot={this.onSaveMiniBot}
                    onchangeSupportedStates={this.onchangeSupportedStates}
                    onchangeSupportedCountries={this.onchangeSupportedCountries}
                    onchangeSupportedTimezones={this.onchangeSupportedTimezones}
                    onHandleBookingSystemChange={this.onHandleBookingSystemChange}
                    onHandleMiniCityChange={this.onHandleMiniCityChange}
                    onHandleMiniNotesChange={this.onHandleMiniNotesChange}
                    onHandleMiniPhoneChange={this.onHandleMiniPhoneChange}
                    onHandleMiniStreetAddressChange={this.onHandleMiniStreetAddressChange}
                    onHandleMiniVersionChange={this.onHandleMiniVersionChange}
                    onHandleMiniZipCodeChange={this.onHandleMiniZipCodeChange}
                    onHandleMiniWebsiteURLChange={this.onHandleMiniWebsiteURLChange}
                    supportedStates={this.state.supportedStates}
                    supportedCountries={this.state.supportedCountries}
                    supportedTimezones={this.state.supportedTimezones}
                    miniBotBookingSystem={this.state.miniBotBookingSystem}
                    miniBotCity={this.state.miniBotCity}
                    miniBotCountry={this.state.miniBotCountry}
                    miniBotNotes={this.state.miniBotNotes}
                    miniBotPhoneNumber={this.state.miniBotPhoneNumber}
                    miniBotState={this.state.miniBotState}
                    miniBotStreetAddress={this.state.miniBotStreetAddress}
                    miniBotTimeZoneId={this.state.miniBotTimeZoneId}
                    miniBotVersion={this.state.miniBotVersion}
                    miniBotZipCode={this.state.miniBotZipCode}
                    miniBotWebsiteUrl={this.state.miniBotWebsiteUrl}
                    miniBotSupportedCountry={this.state.miniBotSupportedCountry}
                    supportedBookingSystems={this.state.supportedBookingSystems}
                />
                <CreateDuplicateSeries
                    show={this.state.showDuplicateSeriesModal}
                    onHide={() => this.onShowDuplicateSeriesModal(false)}
                    originalSeriesId={this.state.originalSeriesId}
                    originalName={this.state.originalName}
                    updateTemplateInformationFromNewDuplicate={this.updateTemplateInformationFromNewDuplicate}
                />
            </Container >
        )
    }
}